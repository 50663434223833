@import './styles.css';

.App {
  text-align: center;
  z-index: 5;
}

.canvas-container {
  width: 100vw;  
  height: 100vh; 
  display: block;
}

.smoothed {
  position: fixed;
  bottom: 4%; 
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .canvas-container {
    height: 100vh;
  }
}
